<template>
  <div id="404">
    <div class="not_found_root_container">
      <div class="not_found_root_container__item title">404</div>
      <div class="not_found_root_container__item button">
        <button @click="$router.push('/terminals')">На главную</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";

.not_found_root_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;

  .not_found_root_container__item {
    &.title {
      color: white;
      font-size: 100px;
    }
    &.button {
      margin-top: 13px;
      button {
        height: 46px;
        width: 320px;
        background: #ffffff;
        cursor: pointer;
        border: 0.5px solid rgba(0, 0, 0, 0.12);
        border-radius: 10px;

        font-family: "Roboto";
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: 010101;
      }
    }
  }
}
</style>
