import axios, {AxiosResponse, AxiosError}  from 'axios';
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { panelBackUrl } from '@/config'
// import router from '../router';


export default createStore({
  plugins: [createPersistedState()],
  state: {
    filters_encoded: null,
    authToken: null,
    refreshToken: null,
    userData: {fullName: null, login: null},
    rights: null
  },
  mutations: {
    SET_USER_DATA: (state, payload) => {
      state.userData.fullName = payload.fullName
      state.userData.login = payload.login
    },
    SET_AUTH_TOKEN: (state, payload) => {
      state.authToken = payload
    },
    SET_REFRESH_TOKEN: (state, payload) => {
      state.refreshToken = payload
    },
    SET_FILTERS_ENCODED: (state, payload) => {
      state.filters_encoded = payload
    },
    RESET_AUTH_TOKEN: (state) => {
      state.authToken = null
    },
    RESET_REFRESH_TOKEN: (state) => {
      state.refreshToken = null
    },
    RESET_USER_DATA: (state) => {
      state.userData = {fullName: null, login: null}
    },
    SET_USER_RIGHTS: (state, payload) => {
      state.rights = payload
    },
    RESET_USER_RIGHTS: (state) => {
      state.rights = null
    },
  },
  getters: {
    AUTH_TOKEN: (state) => {
      return state.authToken
    },
    REFRESH_TOKEN: (state) => {
      return state.refreshToken
    },
    FILTERS_ENCODED: (state) => {
      return state.filters_encoded
    },
    USER_DATA: (state) => {
      return state.userData
    },
    USER_RIGHTS: (state) => {
      return state.rights
    },
  },
  actions: {
    SAVE_AUTH_TOKEN: async (context, payload) => {
      context.commit('SET_AUTH_TOKEN', payload);
    },
    SAVE_REFRESH_TOKEN: async (context, payload) => {
      context.commit('SET_REFRESH_TOKEN', payload);
    },
    SAVE_FILTERS_ENCODED: async (context, payload) => {
      context.commit('SET_FILTERS_ENCODED', payload);
    },
    UPDATE_AUTH_TOKEN: async (context) => {
      console.log("Try to get refresh auth tokens")
      return axios.put(
        `${panelBackUrl}/v1/auth`,
        {
          refreshToken: context.getters.REFRESH_TOKEN
        }
        )
        .then(
          async(response: AxiosResponse) => {
            console.log("Tokens updated", response)
            await context.dispatch('SAVE_AUTH_TOKEN', response.data.accessToken)
            await context.dispatch('SAVE_REFRESH_TOKEN', response.data.refreshToken)
            return true
          }
        )
        .catch(async(err: AxiosError) => {
        // Handle error.
          console.log("Error to update Tokens move to login", err)
          return false
          
        });

    },
    SAVE_USER_DATA: async (context, payload) => {
      context.commit('SET_USER_DATA', payload);
    },

    GET_AUTH_TOKEN: async (context) => {
      return context.getters.AUTH_TOKEN
    },

    RESET_AUTH: async (context) => {
      context.commit('RESET_AUTH_TOKEN');
      context.commit('RESET_REFRESH_TOKEN');
      context.commit('RESET_USER_DATA');
      context.commit('RESET_USER_RIGHTS');
    },

    SET_USER_RIGHTS: async (context, payload) => {
      context.commit('SET_USER_RIGHTS', payload);
    },

    RESET_USER_RIGHTS: async (context) => {
      context.commit('RESET_USER_RIGHTS');
    }

  },
  modules: {
  }
})
